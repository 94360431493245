import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertCtrl: AlertController
  ) { }

  showErrorMobileAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        // eslint-disable-next-line max-len
        message: 'Είσαι πολύ κοντά!<br/><br/>Για να συμμετάσχεις στην κλήρωση, πρέπει να εγγραφείς στο Club de Beauté.<br/><br/>Ενημερώσου στο ταμείο, γίνε μέλος & σκάναρε τον κωδικό.<br/><br/>Μπορεί να είσαι εσύ ο μεγάλος νικητής!',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorMobileAlreadyParticipateAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Ειδοποίηση',
        // eslint-disable-next-line max-len
        message: 'Ουπς!<br/><br/>Έχεις ήδη μπει στην κλήρωση για το μεγάλο δώρο.<br/><br/>Καλή επιτυχία!',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorMobileResultAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        // eslint-disable-next-line max-len
        message: 'Ουπς!<br/><br/>Το κινητό σου συνδέεται με περισσότερες από μία κάρτες προνομίων Club de Beauté.<br/><br/>Κάλεσε στο 211 780 2460 για να μπορέσεις να μπεις στην κλήρωση.<br/><br/>Μπορεί να είσαι εσύ ο μεγάλος νικητής!',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorOTPAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        message: 'O κωδικός που έχετε εισάγει είναι λάθος!<br/><br/>Παρακαλώ ξαναπροσπαθήστε!',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorMaxOTPAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        message: 'Ξεπεράσατε τον μέγιστο αριθμό προσπαθειών για σήμερα.<br/><br/>Παρακαλώ δοκιμάστε και πάλι αύριο.',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorNetworkAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        message: 'Παρακαλώ ελέγξετε την σύνδεση στο διαδίκτυο!',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }

  showErrorGeneralAlert() {
    this.alertCtrl.create(
      {
        cssClass: 'my-custom-class',
        // header: 'Σφάλμα',
        message: 'Άγνωστο σφάλμα!<br/><br/>Δοκιμάστε ξανά αργότερα.',
        buttons: [
          'Ok'
        ]
      }
    ).then(alertEl => {
      alertEl.present();
    });
  }
}
