import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { DatePipe } from '@angular/common';
import { HttpErrorInterceptor } from './interceptors/httpError.interceptos';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    HttpClientModule
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy 
    },
    {
      provide: 'qcURL',
      useValue: environment.qcURL,
    },
    {
      provide: 'qivosApiURL',
      useValue: environment.qivosApiURL,
    },
    {
      provide: 'clientName',
      useValue: 'gdb',
    },
    {
      provide: 'qivosRestURL',
      useValue: environment.qivosRestURL,
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: HttpErrorInterceptor, 
      multi: true 
    },
    QivosCloudService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
